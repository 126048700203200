// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-responsive {
    overflow-x: auto; /* Enable horizontal scrolling */
}

.communication-table {
    width: 100%; /* Ensure the table takes up full width */
    table-layout: auto; /* Allow columns to adjust automatically */
}

.communication-table th, .communication-table td {
    white-space: nowrap; /* Prevent text wrapping in cells */
    text-align: left; /* Align content to the left */
    padding: 8px 12px; /* Add padding for better readability */
}

.communication-table th {
    background-color: #f8f9fa; /* Light background for headers */
    color: #000;
    border: 1px solid #ddd;
}

.communication-table tr:hover {
    background-color: #f5f5f5;
}


.communication-table td {
    vertical-align: middle; /* Align content vertically */
    border: 1px solid #ddd;
    color: #000;
}

.communication-table tbody tr {
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/client/Reservation/Dashboard/MoreDetailsTab/Communication.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB,EAAE,gCAAgC;AACtD;;AAEA;IACI,WAAW,EAAE,yCAAyC;IACtD,kBAAkB,EAAE,0CAA0C;AAClE;;AAEA;IACI,mBAAmB,EAAE,mCAAmC;IACxD,gBAAgB,EAAE,8BAA8B;IAChD,iBAAiB,EAAE,uCAAuC;AAC9D;;AAEA;IACI,yBAAyB,EAAE,iCAAiC;IAC5D,WAAW;IACX,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;AAC7B;;;AAGA;IACI,sBAAsB,EAAE,6BAA6B;IACrD,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".table-responsive {\r\n    overflow-x: auto; /* Enable horizontal scrolling */\r\n}\r\n\r\n.communication-table {\r\n    width: 100%; /* Ensure the table takes up full width */\r\n    table-layout: auto; /* Allow columns to adjust automatically */\r\n}\r\n\r\n.communication-table th, .communication-table td {\r\n    white-space: nowrap; /* Prevent text wrapping in cells */\r\n    text-align: left; /* Align content to the left */\r\n    padding: 8px 12px; /* Add padding for better readability */\r\n}\r\n\r\n.communication-table th {\r\n    background-color: #f8f9fa; /* Light background for headers */\r\n    color: #000;\r\n    border: 1px solid #ddd;\r\n}\r\n\r\n.communication-table tr:hover {\r\n    background-color: #f5f5f5;\r\n}\r\n\r\n\r\n.communication-table td {\r\n    vertical-align: middle; /* Align content vertically */\r\n    border: 1px solid #ddd;\r\n    color: #000;\r\n}\r\n\r\n.communication-table tbody tr {\r\n    cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
