import React from 'react';
import HistoryPageHeader from './HistoryPageHeader';

export const History = () => {
    const historyData = [
        {
            icon: 'fa-arrow-right',
            title: 'Reservation status changed',
            oldValue: 'reservation status provisional',
            newValue: 'reservation status definitive',
            date: 'Tu 13 Aug 2024, 20:56:11',
        },
        {
            icon: 'fa-check-circle',
            title: 'Payment received',
            newValue: '€652.43 via Internet',
            date: 'Tu 23 Jul 2024, 15:04:39',
        },
        {
            icon: 'fa-arrow-right',
            title: 'Reservation validation status changed',
            oldValue: 'To Check',
            newValue: 'Sent',
            date: 'Tu 23 Jul 2024, 14:59:27',
        },
        {
            icon: 'fa-arrow-right',
            title: 'Quantity changed',
            newValue: 'Sent',
            date: 'Tu 23 Jul 2024, 14:59:27',
        },
        {
            icon: 'fa-check-circle',
            title: 'Resource added',
            oldValue: 'To Check',
            newValue: 'Sent',
            date: 'Tu 23 Jul 2024, 14:59:27',
        },
        // Add more entries as needed
    ];

    return (
        <div className="container-fluid">
            <HistoryPageHeader />
            <div className="main-container bg-white rounded shadow-sm">
                <div className="card-title">
                    <h5 className="font-weight-bold text-black">
                        <strong>History</strong>
                    </h5>
                </div>
                <hr />
                <input type="text" className="form-control mb-3" placeholder="Search" />
                <hr />
                <div className="history-container">
                    {historyData.map((item, index) => (
                        <div key={index} className="history-item mb-3">
                            <div className="d-flex align-items-start">
                                <i className={`fa ${item.icon} text-primary mr-3 mt-1`} />
                                <div className="history-content w-100">
                                    <p className="mb-1 text-black">
                                        <strong>{item.title}</strong>
                                    </p>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            {item.oldValue && <span className="text-danger mr-2">{item.oldValue}</span>}
                                            {item.newValue && <span className="text-success">{item.newValue}</span>}
                                        </div>
                                        <div className="text-muted small">{item.date}</div>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-2" />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default History;
