import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Form, Tab, Tabs } from 'react-bootstrap';

const HistoryPageHeader = () => {
    return (
        <div className="reservation-container">
            <div className="main-content">
                <header className="page-header page-header-compact page-header-light border-bottom bg-white">
                    <div className="page-header-content">
                        <button type="button" className="btn btn-outline-dark btn-sm ml-4 mb-4 mt-1">
                            Call center (DBCC)/reserver
                        </button>
                        <div className="row align-items-center text-start">
                            <div className="col-2">
                                <p>
                                    <strong>Status:</strong> <br /> <span className="badge bg-secondary">Initial</span>
                                </p>
                            </div>
                            <div className="col-2">
                                <p>
                                    <strong>Arrival - Departure:</strong> <br /> -
                                </p>
                            </div>
                            <div className="col-2">
                                <p>
                                    <strong>Last Modified:</strong> <br /> Wed 04/12/2024
                                </p>
                            </div>
                            <div className="col-2">
                                <p>
                                    <strong>Reservation Nr:</strong> <br /> -
                                </p>
                            </div>
                            <div className="col-2">
                                <p>
                                    <strong>Reservation Date:</strong> <br /> Wed 04/12/2024
                                </p>
                            </div>
                        </div>
                    </div>
                    <Tabs className="mb-3 mt-2 ">
                        {/* Tab 1 Content (Quotations)*/}
                        <Tab eventKey="customer details" title="Customer details">
                            <div className="card-body">
                                {/* Sub-Tab-2 under customer-detail tab*/}
                                <Tabs id="Customer" className="mb-3 ml-1">
                                    {/* Tab 1 Content (Changed)*/}
                                    <Tab eventKey="Customer" title="Customer">
                                        <div className="container-fluid" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                            <div
                                                className="card-title"
                                                style={{ backgroundColor: '#f8d7da', color: '#721c24', padding: '10px' }}
                                            >
                                                <i className="fa-solid fa-circle-xmark" /> {''}
                                                <strong>Be aware!</strong>
                                            </div>
                                            <div
                                                className="card-body"
                                                style={{
                                                    backgroundColor: '#fef2f2',
                                                    color: '#721c24',
                                                    paddingLeft: '2rem',
                                                }}
                                            >
                                                <p>Some fields in this form require your attention.</p>
                                            </div>
                                            <br />
                                            <div className="row">
                                                {/* Left Side Content */}
                                                <div className="col-md-6">
                                                    <div className="card-body mb-5">
                                                        <Form className="mb-4 mt-1">
                                                            <Form.Group controlId="formCustomerid">
                                                                <Form.Label>
                                                                    <strong>Customer ID</strong>
                                                                </Form.Label>
                                                                <Form.Control type="text" defaultValue="12345678" />
                                                            </Form.Group>
                                                            <br />
                                                            <Form.Group>
                                                                <Form.Label>
                                                                    <strong>Title</strong>
                                                                </Form.Label>
                                                                <div className="dropdown-with-caret">
                                                                    <Form.Control as="select" defaultValue="">
                                                                        <option>Mr.</option>
                                                                    </Form.Control>
                                                                    <span className="caret" />
                                                                </div>
                                                            </Form.Group>
                                                            <br />
                                                            <div className="row">
                                                                <div className="col-4">
                                                                    <Form.Group controlId="formFirstName">
                                                                        <Form.Label>
                                                                            <strong>Firt Name</strong>
                                                                        </Form.Label>
                                                                        <Form.Control type="text" defaultValue="***" />
                                                                    </Form.Group>
                                                                </div>
                                                                <br />
                                                                <div className="col-4">
                                                                    <Form.Group controlId="formMiddleName">
                                                                        <Form.Label>
                                                                            <strong>Middle name</strong>
                                                                        </Form.Label>
                                                                        <Form.Control type="text" defaultValue="***" />
                                                                    </Form.Group>
                                                                </div>
                                                                <br />
                                                                <div className="col-4">
                                                                    <Form.Group controlId="formLastName">
                                                                        <Form.Label>
                                                                            <strong>Last Name</strong>
                                                                        </Form.Label>
                                                                        <Form.Control type="text" defaultValue="***" />
                                                                    </Form.Group>
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <Form.Group controlId="formAttensionof">
                                                                <Form.Label>
                                                                    <strong>Attention of</strong>
                                                                </Form.Label>
                                                                <Form.Control type="text" defaultValue="" />
                                                            </Form.Group>
                                                            <br />
                                                            <div className="row">
                                                                <div className="col-4">
                                                                    <Form.Group>
                                                                        <Form.Label>
                                                                            <strong>Gender</strong>
                                                                        </Form.Label>
                                                                        <div className="dropdown-with-caret">
                                                                            <Form.Control as="select" defaultValue="">
                                                                                <option>Male</option>
                                                                            </Form.Control>
                                                                            <span className="caret" />
                                                                        </div>
                                                                    </Form.Group>
                                                                    <Form.Group>
                                                                        <div className="dropdown-with-caret">
                                                                            <Form.Control as="select" defaultValue="">
                                                                                <option>***</option>
                                                                            </Form.Control>
                                                                            <span className="caret" />
                                                                        </div>
                                                                    </Form.Group>
                                                                </div>
                                                                <br />
                                                                <div className="col-4">
                                                                    <Form.Group controlId="formLanguage">
                                                                        <Form.Label>
                                                                            <strong>Language</strong>
                                                                        </Form.Label>
                                                                        <Form.Control type="text" defaultValue="***" />
                                                                    </Form.Group>
                                                                </div>
                                                                <br />
                                                                <div className="col-4">
                                                                    <Form.Group controlId="formDateofbirth">
                                                                        <Form.Label>
                                                                            <strong>Date of birth</strong>
                                                                        </Form.Label>
                                                                        <Form.Control type="date" defaultValue="***" />
                                                                    </Form.Group>
                                                                </div>
                                                            </div>
                                                        </Form>
                                                        {/*Personal E-N-D---------------------------------------------------------------*/}
                                                        <hr />
                                                        <h5>
                                                            <strong>Bank Account</strong>
                                                        </h5>
                                                        <Form className="mb-4 mt-4">
                                                            <Form.Group controlId="formIban">
                                                                <Form.Label>
                                                                    <strong>IBAN</strong>
                                                                </Form.Label>
                                                                <Form.Control type="text" defaultValue="" />
                                                            </Form.Group>
                                                            <br />
                                                            <Form.Group controlId="formAlternativeName">
                                                                <Form.Label>
                                                                    <strong>Alternative Name</strong>
                                                                </Form.Label>
                                                                <Form.Control type="text" defaultValue="" />
                                                            </Form.Group>
                                                        </Form>
                                                        {/*Bank details E-N-D---------------------------------------------------------------*/}
                                                        <hr />
                                                        <h5>
                                                            <strong>Company</strong>
                                                        </h5>
                                                        <Form className="mb-4 mt-4">
                                                            <Form.Group controlId="formCompanyname">
                                                                <Form.Label>
                                                                    <strong>Company Name</strong>
                                                                </Form.Label>
                                                                <Form.Control type="text" defaultValue="" />
                                                            </Form.Group>
                                                            <br />
                                                            <Form.Group controlId="formDepartment">
                                                                <Form.Label>
                                                                    <strong>Department</strong>
                                                                </Form.Label>
                                                                <Form.Control type="text" defaultValue="" />
                                                            </Form.Group>
                                                            <br />
                                                            <Form.Group controlId="formCompanyidentificationno.">
                                                                <Form.Label>
                                                                    <strong>Company identification number</strong>
                                                                </Form.Label>
                                                                <Form.Control type="text" defaultValue="" />
                                                            </Form.Group>
                                                            <br />
                                                            <Form.Group controlId="formVatno.">
                                                                <Form.Label>
                                                                    <strong>VAT number</strong>
                                                                </Form.Label>
                                                                <Form.Control type="text" defaultValue="" />
                                                            </Form.Group>
                                                        </Form>
                                                        {/*Company details E-N-D---------------------------------------------------------------*/}
                                                        <hr />
                                                        <h5>
                                                            <strong>Mailing</strong>
                                                        </h5>
                                                        <Form className="mb-4 mt-4">
                                                            <Form.Group>
                                                                <Form.Label>
                                                                    <strong>Send method reservation communication</strong>
                                                                </Form.Label>
                                                                <div className="dropdown-with-caret">
                                                                    <Form.Control as="select" defaultValue="">
                                                                        <option>Select location</option>
                                                                    </Form.Control>
                                                                    <span className="caret" />
                                                                </div>
                                                            </Form.Group>
                                                            {/*Mail details E-N-D---------------------------------------------------------------*/}
                                                            <hr />
                                                            <h5>
                                                                <strong>Source</strong>
                                                            </h5>
                                                            <Form className="mb-4 mt-4">
                                                                <Form.Group>
                                                                    <Form.Label>
                                                                        <strong>
                                                                            Source from where user might heard about booking
                                                                        </strong>
                                                                    </Form.Label>
                                                                    <div className="dropdown-with-caret">
                                                                        <Form.Control as="select" defaultValue="">
                                                                            <option>No source</option>
                                                                        </Form.Control>
                                                                        <span className="caret" />
                                                                    </div>
                                                                </Form.Group>
                                                            </Form>
                                                            {/*Source details E-N-D---------------------------------------------------------------*/}
                                                        </Form>
                                                    </div>
                                                </div>
                                                {/* Right Side Content--------------------------------------------------------------- */}
                                                <div className="col-md-6">
                                                    <div className="card-body mb-5">
                                                        <Form>
                                                            <Form.Group className="mb-4">
                                                                <div className="dropdown-with-caret">
                                                                    <Form.Control as="select" defaultValue="">
                                                                        <option>Select location</option>
                                                                    </Form.Control>
                                                                    <span className="caret" />
                                                                </div>
                                                            </Form.Group>
                                                            <hr />
                                                        </Form>
                                                        <br />
                                                        <Form>
                                                            <Form.Group>
                                                                <Form.Label>
                                                                    <strong>Country</strong>
                                                                </Form.Label>
                                                                <div className="dropdown-with-caret">
                                                                    <Form.Control as="select" defaultValue="">
                                                                        <option>Germany</option>
                                                                    </Form.Control>
                                                                    <span className="caret" />
                                                                </div>
                                                            </Form.Group>
                                                            <br />
                                                            <Form.Group controlId="formCompanyidentificationno.">
                                                                <Form.Label>
                                                                    <strong>Address</strong>
                                                                </Form.Label>
                                                                <Form.Control type="text" defaultValue="" />
                                                            </Form.Group>
                                                            <br />
                                                            <div className="row mb-4">
                                                                <div className="col-4">
                                                                    <Form.Group controlId="formZipcode">
                                                                        <Form.Label>
                                                                            <strong>Zipcode</strong>
                                                                        </Form.Label>
                                                                        <Form.Control type="text" defaultValue="***" />
                                                                    </Form.Group>
                                                                </div>
                                                                <br />
                                                                <div className="col-7">
                                                                    <Form.Group controlId="formPincode">
                                                                        <Form.Label>
                                                                            <strong>Pincode</strong>
                                                                        </Form.Label>
                                                                        <Form.Control type="text" defaultValue="***" />
                                                                    </Form.Group>
                                                                </div>
                                                            </div>
                                                            {/*Contact details Start---------------------------------------------------------------*/}
                                                            <hr />
                                                            <Form className="mt-4">
                                                                <Form.Group controlId="formEmail">
                                                                    <Form.Label>
                                                                        <strong>Email</strong>
                                                                    </Form.Label>
                                                                    <Form.Control type="text" defaultValue="***" />
                                                                </Form.Group>
                                                            </Form>
                                                            <br />
                                                            <Form className="mt-4">
                                                                <Form.Group controlId="formSecomdaryemail">
                                                                    <Form.Label>
                                                                        <strong>Secondary email</strong>
                                                                    </Form.Label>
                                                                    <Form.Control type="text" defaultValue="***" />
                                                                </Form.Group>
                                                            </Form>
                                                            <br />
                                                            <Form className="mt-4">
                                                                <Form.Group controlId="formPhone">
                                                                    <Form.Label>
                                                                        <strong>Phone</strong>
                                                                    </Form.Label>
                                                                    <Form.Control type="text" defaultValue="***" />
                                                                </Form.Group>
                                                            </Form>
                                                            <br />
                                                            <Form className="mt-4">
                                                                <Form.Group controlId="formWorkphone">
                                                                    <Form.Label>
                                                                        <strong>Work phone</strong>
                                                                    </Form.Label>
                                                                    <Form.Control type="text" defaultValue="***" />
                                                                </Form.Group>
                                                            </Form>
                                                            <br />
                                                            <Form className="mt-4">
                                                                <Form.Group controlId="formMobilephone">
                                                                    <Form.Label>
                                                                        <strong>Mobile phone</strong>
                                                                    </Form.Label>
                                                                    <Form.Control type="text" defaultValue="***" />
                                                                </Form.Group>
                                                            </Form>
                                                            <br />
                                                            <Form className="mt-4">
                                                                <Form.Group controlId="formSecondaryphone">
                                                                    <Form.Label>
                                                                        <strong>Secondary phone</strong>
                                                                    </Form.Label>
                                                                    <Form.Control type="text" defaultValue="***" />
                                                                </Form.Group>
                                                            </Form>
                                                        </Form>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*Memo Details starts----------------------------------------------------*/}
                                            <div
                                                className="card-title"
                                                style={{ backgroundColor: '#f8d7da', color: '#721c24', padding: '10px' }}
                                            >
                                                <i className="fa-solid fa-circle-xmark" /> {''}
                                                <strong>Be aware!</strong>
                                            </div>
                                            <div
                                                className=""
                                                style={{
                                                    backgroundColor: '#fef2f2',
                                                    color: '#721c24',
                                                    paddingLeft: '2rem',
                                                }}
                                            >
                                                <p>Some fields in this form require your attention.</p>
                                            </div>
                                            <hr />
                                            <div className="text-end">
                                                <a
                                                    className="text-danger"
                                                    href="#"
                                                    style={{ marginRight: '10px', textDecoration: 'none' }} // Added textDecoration
                                                >
                                                    <strong>New customer</strong>
                                                </a>

                                                <a
                                                    className="text ml-3"
                                                    href="#"
                                                    style={{ marginRight: '10px', textDecoration: 'none' }} // Added textDecoration
                                                >
                                                    <strong>save</strong>
                                                </a>
                                            </div>
                                            {/*warning error------------------------------------------------------------*/}
                                            <div className="text-black mt-4">
                                                <h6>
                                                    <strong>Memos / Past reservations / Brouchers</strong>
                                                </h6>
                                            </div>
                                            <hr />
                                            {/* Sub-Tab-3 under customer-details tab*/}
                                            <Tabs id="Memo" className="mb-3 mt-2 ml-1">
                                                {/* Tab 1 Content (Memo)*/}
                                                <Tab eventKey="Memo" title="Memo">
                                                    <h6 className="text-black mt-4">There are no memos available</h6>
                                                    <hr />
                                                    <div className="text-end">
                                                        <a
                                                            href="#"
                                                            style={{ marginRight: '10px', textDecoration: 'none' }} // Added textDecoration
                                                        >
                                                            <strong>Create memo</strong>
                                                        </a>
                                                    </div>
                                                    <hr />
                                                    <div className="text-end">
                                                        <a
                                                            className="text-black"
                                                            href="#"
                                                            style={{ marginRight: '10px', textDecoration: 'none' }} // Added textDecoration
                                                        >
                                                            <strong>All reservation</strong>
                                                        </a>
                                                        <a
                                                            className="ml-3"
                                                            href="#"
                                                            style={{ marginRight: '10px', textDecoration: 'none' }} // Added textDecoration
                                                        >
                                                            <strong>New reservation</strong>
                                                        </a>
                                                    </div>
                                                    <hr />
                                                    <div className="text-end">
                                                        <a
                                                            className="text-black"
                                                            href="#"
                                                            style={{ marginRight: '10px', textDecoration: 'none' }} // Added textDecoration
                                                        >
                                                            <strong>Search accomodation type</strong>
                                                        </a>
                                                        <a
                                                            className="ml-3"
                                                            href="#"
                                                            style={{ marginRight: '10px', textDecoration: 'none' }} // Added textDecoration
                                                        >
                                                            <strong>Show overview</strong>
                                                        </a>
                                                    </div>
                                                </Tab>
                                                {/* Tab 2 Content (Quotations)*/}
                                                <Tab eventKey="quotaions" title="Customer details">
                                                    <h3>Coming soon</h3>
                                                </Tab>
                                                {/* Tab 3 Content (Completed)*/}
                                                <Tab eventKey="completed" title="Overview">
                                                    <h3>Coming Soon</h3>
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </Tab>
                                    {/* Sub-Tab 2 Content (Quotations)*/}
                                    <Tab eventKey="paying customer" title="Paying customer">
                                        <div className="container-fluid" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                            <div
                                                className="card-title"
                                                style={{ backgroundColor: '#f8d7da', color: '#721c24', padding: '10px' }}
                                            >
                                                <i className="fa-solid fa-circle-xmark" /> {''}
                                                <strong>Be aware!</strong>
                                            </div>
                                            <div
                                                className="card-body"
                                                style={{
                                                    backgroundColor: '#fef2f2',
                                                    color: '#721c24',
                                                    paddingLeft: '2rem',
                                                }}
                                            >
                                                <p>Some fields in this form require your attention.</p>
                                            </div>
                                            <br />
                                            <div className="row">
                                                {/* Left Side Content */}
                                                <div className="col-md-6">
                                                    <div className="card-body mb-5">
                                                        <Form className="mb-4 mt-1">
                                                            <Form.Group controlId="formCustomerid">
                                                                <Form.Label>
                                                                    <strong>Customer ID</strong>
                                                                </Form.Label>
                                                                <Form.Control type="text" defaultValue="12345678" />
                                                            </Form.Group>
                                                            <br />
                                                            <Form.Group>
                                                                <Form.Label>
                                                                    <strong>Title</strong>
                                                                </Form.Label>
                                                                <div className="dropdown-with-caret">
                                                                    <Form.Control as="select" defaultValue="">
                                                                        <option>Mr.</option>
                                                                    </Form.Control>
                                                                    <span className="caret" />
                                                                </div>
                                                            </Form.Group>
                                                            <br />
                                                            <div className="row">
                                                                <div className="col-4">
                                                                    <Form.Group controlId="formFirstName">
                                                                        <Form.Label>
                                                                            <strong>Firt Name</strong>
                                                                        </Form.Label>
                                                                        <Form.Control type="text" defaultValue="***" />
                                                                    </Form.Group>
                                                                </div>
                                                                <br />
                                                                <div className="col-4">
                                                                    <Form.Group controlId="formMiddleName">
                                                                        <Form.Label>
                                                                            <strong>Middle name</strong>
                                                                        </Form.Label>
                                                                        <Form.Control type="text" defaultValue="***" />
                                                                    </Form.Group>
                                                                </div>
                                                                <br />
                                                                <div className="col-4">
                                                                    <Form.Group controlId="formLastName">
                                                                        <Form.Label>
                                                                            <strong>Last Name</strong>
                                                                        </Form.Label>
                                                                        <Form.Control type="text" defaultValue="***" />
                                                                    </Form.Group>
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <Form.Group controlId="formAttensionof">
                                                                <Form.Label>
                                                                    <strong>Attention of</strong>
                                                                </Form.Label>
                                                                <Form.Control type="text" defaultValue="" />
                                                            </Form.Group>
                                                            <br />
                                                            <div className="row">
                                                                <div className="col-4">
                                                                    <Form.Group>
                                                                        <Form.Label>
                                                                            <strong>Gender</strong>
                                                                        </Form.Label>
                                                                        <div className="dropdown-with-caret">
                                                                            <Form.Control as="select" defaultValue="">
                                                                                <option>Male</option>
                                                                            </Form.Control>
                                                                            <span className="caret" />
                                                                        </div>
                                                                    </Form.Group>
                                                                    <Form.Group>
                                                                        <div className="dropdown-with-caret">
                                                                            <Form.Control as="select" defaultValue="">
                                                                                <option>***</option>
                                                                            </Form.Control>
                                                                            <span className="caret" />
                                                                        </div>
                                                                    </Form.Group>
                                                                </div>
                                                                <br />
                                                                <div className="col-4">
                                                                    <Form.Group controlId="formLanguage">
                                                                        <Form.Label>
                                                                            <strong>Language</strong>
                                                                        </Form.Label>
                                                                        <Form.Control type="text" defaultValue="***" />
                                                                    </Form.Group>
                                                                </div>
                                                                <br />
                                                                <div className="col-4">
                                                                    <Form.Group controlId="formDateofbirth">
                                                                        <Form.Label>
                                                                            <strong>Date of birth</strong>
                                                                        </Form.Label>
                                                                        <Form.Control type="date" defaultValue="***" />
                                                                    </Form.Group>
                                                                </div>
                                                            </div>
                                                        </Form>
                                                        {/*Personal E-N-D---------------------------------------------------------------*/}
                                                        <hr />
                                                        <h5>
                                                            <strong>Bank Account</strong>
                                                        </h5>
                                                        <Form className="mb-4 mt-4">
                                                            <Form.Group controlId="formIban">
                                                                <Form.Label>
                                                                    <strong>IBAN</strong>
                                                                </Form.Label>
                                                                <Form.Control type="text" defaultValue="" />
                                                            </Form.Group>
                                                            <br />
                                                            <Form.Group controlId="formAlternativeName">
                                                                <Form.Label>
                                                                    <strong>Alternative Name</strong>
                                                                </Form.Label>
                                                                <Form.Control type="text" defaultValue="" />
                                                            </Form.Group>
                                                        </Form>
                                                        {/*Bank details E-N-D---------------------------------------------------------------*/}
                                                        <hr />
                                                        <h5>
                                                            <strong>Company</strong>
                                                        </h5>
                                                        <Form className="mb-4 mt-4">
                                                            <Form.Group controlId="formCompanyname">
                                                                <Form.Label>
                                                                    <strong>Company Name</strong>
                                                                </Form.Label>
                                                                <Form.Control type="text" defaultValue="" />
                                                            </Form.Group>
                                                            <br />
                                                            <Form.Group controlId="formDepartment">
                                                                <Form.Label>
                                                                    <strong>Department</strong>
                                                                </Form.Label>
                                                                <Form.Control type="text" defaultValue="" />
                                                            </Form.Group>
                                                            <br />
                                                            <Form.Group controlId="formCompanyidentificationno.">
                                                                <Form.Label>
                                                                    <strong>Company identification number</strong>
                                                                </Form.Label>
                                                                <Form.Control type="text" defaultValue="" />
                                                            </Form.Group>
                                                            <br />
                                                            <Form.Group controlId="formVatno.">
                                                                <Form.Label>
                                                                    <strong>VAT number</strong>
                                                                </Form.Label>
                                                                <Form.Control type="text" defaultValue="" />
                                                            </Form.Group>
                                                        </Form>
                                                        {/*Company details E-N-D---------------------------------------------------------------*/}
                                                        <hr />
                                                        <h5>
                                                            <strong>Mailing</strong>
                                                        </h5>
                                                        <Form className="mb-4 mt-4">
                                                            <Form.Group>
                                                                <Form.Label>
                                                                    <strong>Send method reservation communication</strong>
                                                                </Form.Label>
                                                                <div className="dropdown-with-caret">
                                                                    <Form.Control as="select" defaultValue="">
                                                                        <option>Select location</option>
                                                                    </Form.Control>
                                                                    <span className="caret" />
                                                                </div>
                                                            </Form.Group>
                                                            {/*Mail details E-N-D---------------------------------------------------------------*/}
                                                            <hr />
                                                            <h5>
                                                                <strong>Source</strong>
                                                            </h5>
                                                            <Form className="mb-4 mt-4">
                                                                <Form.Group>
                                                                    <Form.Label>
                                                                        <strong>
                                                                            Source from where user might heard about booking
                                                                        </strong>
                                                                    </Form.Label>
                                                                    <div className="dropdown-with-caret">
                                                                        <Form.Control as="select" defaultValue="">
                                                                            <option>No source</option>
                                                                        </Form.Control>
                                                                        <span className="caret" />
                                                                    </div>
                                                                </Form.Group>
                                                            </Form>
                                                            {/*Source details E-N-D---------------------------------------------------------------*/}
                                                        </Form>
                                                    </div>
                                                </div>
                                                {/* Right Side Content--------------------------------------------------------------- */}
                                                <div className="col-md-6">
                                                    <div className="card-body mb-5">
                                                        <Form>
                                                            <Form.Group className="mb-4">
                                                                <div className="dropdown-with-caret">
                                                                    <Form.Control as="select" defaultValue="">
                                                                        <option>Select location</option>
                                                                    </Form.Control>
                                                                    <span className="caret" />
                                                                </div>
                                                            </Form.Group>
                                                            <hr />
                                                        </Form>
                                                        <br />
                                                        <Form>
                                                            <Form.Group>
                                                                <Form.Label>
                                                                    <strong>Country</strong>
                                                                </Form.Label>
                                                                <div className="dropdown-with-caret">
                                                                    <Form.Control as="select" defaultValue="">
                                                                        <option>Germany</option>
                                                                    </Form.Control>
                                                                    <span className="caret" />
                                                                </div>
                                                            </Form.Group>
                                                            <br />
                                                            <Form.Group controlId="formCompanyidentificationno.">
                                                                <Form.Label>
                                                                    <strong>Address</strong>
                                                                </Form.Label>
                                                                <Form.Control type="text" defaultValue="" />
                                                            </Form.Group>
                                                            <br />
                                                            <div className="row mb-4">
                                                                <div className="col-4">
                                                                    <Form.Group controlId="formZipcode">
                                                                        <Form.Label>
                                                                            <strong>Zipcode</strong>
                                                                        </Form.Label>
                                                                        <Form.Control type="text" defaultValue="***" />
                                                                    </Form.Group>
                                                                </div>
                                                                <br />
                                                                <div className="col-7">
                                                                    <Form.Group controlId="formPincode">
                                                                        <Form.Label>
                                                                            <strong>Pincode</strong>
                                                                        </Form.Label>
                                                                        <Form.Control type="text" defaultValue="***" />
                                                                    </Form.Group>
                                                                </div>
                                                            </div>
                                                            {/*Contact details Start---------------------------------------------------------------*/}
                                                            <hr />
                                                            <Form className="mt-4">
                                                                <Form.Group controlId="formEmail">
                                                                    <Form.Label>
                                                                        <strong>Email</strong>
                                                                    </Form.Label>
                                                                    <Form.Control type="text" defaultValue="***" />
                                                                </Form.Group>
                                                            </Form>
                                                            <br />
                                                            <Form className="mt-4">
                                                                <Form.Group controlId="formSecomdaryemail">
                                                                    <Form.Label>
                                                                        <strong>Secondary email</strong>
                                                                    </Form.Label>
                                                                    <Form.Control type="text" defaultValue="***" />
                                                                </Form.Group>
                                                            </Form>
                                                            <br />
                                                            <Form className="mt-4">
                                                                <Form.Group controlId="formPhone">
                                                                    <Form.Label>
                                                                        <strong>Phone</strong>
                                                                    </Form.Label>
                                                                    <Form.Control type="text" defaultValue="***" />
                                                                </Form.Group>
                                                            </Form>
                                                            <br />
                                                            <Form className="mt-4">
                                                                <Form.Group controlId="formWorkphone">
                                                                    <Form.Label>
                                                                        <strong>Work phone</strong>
                                                                    </Form.Label>
                                                                    <Form.Control type="text" defaultValue="***" />
                                                                </Form.Group>
                                                            </Form>
                                                            <br />
                                                            <Form className="mt-4">
                                                                <Form.Group controlId="formMobilephone">
                                                                    <Form.Label>
                                                                        <strong>Mobile phone</strong>
                                                                    </Form.Label>
                                                                    <Form.Control type="text" defaultValue="***" />
                                                                </Form.Group>
                                                            </Form>
                                                            <br />
                                                            <Form className="mt-4">
                                                                <Form.Group controlId="formSecondaryphone">
                                                                    <Form.Label>
                                                                        <strong>Secondary phone</strong>
                                                                    </Form.Label>
                                                                    <Form.Control type="text" defaultValue="***" />
                                                                </Form.Group>
                                                            </Form>
                                                        </Form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                    {/* Sub-Tab 3 Content (Completed)*/}
                                    <Tab eventKey="travel party" title="Travel party">
                                        <h3>Comming Soon</h3>
                                    </Tab>
                                </Tabs>
                            </div>
                        </Tab>
                        {/*END*/}
                        {/* Tab 3 Content (Completed)*/}
                        <Tab eventKey="overview" title="Overview">
                            <div className="container-fluid" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                <div className="row">
                                    {/* Left Sidebar for Search Filters */}
                                    <div className="col-md-7">
                                        {/*Card - 1*/}
                                        <div className="card shadow">
                                            <div className="card-header py-3">
                                                <h5 className="m-0 font-weight-bold text-black">
                                                    <strong>Reservation overview</strong>
                                                </h5>
                                            </div>
                                            <div className="card-body mb-5">
                                                <h6 className="card-title ml-3">Acomodation types</h6>
                                                <hr />
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Term</th>
                                                            <th>
                                                                <i className="fa-solid fa-user" />
                                                            </th>
                                                            <th />
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Beach House 4 nr....</td>
                                                            <td>2</td>
                                                            <td>
                                                                <NavLink to={''}>
                                                                    <i className="fa-solid fa-ellipsis-vertical" />
                                                                </NavLink>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <br />
                                        {/*Card - 2*/}
                                        <div className="card shadow">
                                            <div className="card-header py-3">
                                                <h5 className="m-0 font-weight-bold text-black">
                                                    <strong>Reservation memos</strong>
                                                </h5>
                                            </div>
                                            <div className="card-body">
                                                <h6 className="card-title ml-3 mt-3">There are no memos available</h6>
                                                <hr />
                                                <div className="d-flex justify-content-end">
                                                    <button type="button" className="btn btn-primary me-2">
                                                        Create memo
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        {/*Card - 3*/}
                                        <div className="card shadow">
                                            <div className="card-header py-3">
                                                <h5 className="m-0 font-weight-bold text-black">
                                                    <strong>Reference information</strong>
                                                </h5>
                                            </div>
                                            <div className="card-body">
                                                <div className="row align-items-center ml-2">
                                                    <div className="col-6">
                                                        <p>
                                                            <strong>Reservation number</strong> <br />
                                                            00026000070
                                                        </p>
                                                    </div>
                                                    <div className="col-6">
                                                        <p>
                                                            <strong>Voucher</strong> <br /> Not available
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row align-items-center ml-2">
                                                    <div className="col-6">
                                                        <p>
                                                            <strong>Booking reference number</strong> <br /> Not available
                                                        </p>
                                                    </div>
                                                    <div className="col-6">
                                                        <p>
                                                            <strong>Supplier booking reference number</strong> <br /> Not
                                                            availble
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row align-items-center ml-2">
                                                    <div className="col-12">
                                                        <p>
                                                            <strong>Previous PMS reservation ID</strong> <br /> Not available
                                                        </p>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary">
                                                        Edit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        {/*Card - 4*/}
                                        <div className="card shadow">
                                            <div className="card-header py-3">
                                                <h5 className="m-0 font-weight-bold text-black">
                                                    <strong>Installments</strong>
                                                </h5>
                                            </div>
                                            <div className="card-body">
                                                <Tabs id="Customer" className="mb-3 mt-2 ml-1">
                                                    {/* Tab 1 Content (Changed)*/}
                                                    <Tab eventKey="Customer" title="Customer">
                                                        <table className="employee-table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Term</th>
                                                                    <th>Issue date</th>
                                                                    <th>Due date</th>
                                                                    <th>Status</th>
                                                                    <th>Due amount</th>
                                                                    <th>Paid amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>50% Aanbetaling</td>
                                                                    <td>Closed</td>
                                                                    <td>Tu 06/08/2024</td>
                                                                    <td>Closed</td>
                                                                    <td>
                                                                        <strong>€0.00</strong>
                                                                    </td>
                                                                    <td>
                                                                        <strong>€326.22</strong>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Restbedrag</td>
                                                                    <td>Tu 23/07/2024</td>
                                                                    <td>Fr 07/11/2025</td>
                                                                    <td>Closed</td>
                                                                    <td>
                                                                        <strong>€0.00</strong>
                                                                    </td>
                                                                    <td>
                                                                        <strong>€326.21</strong>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Tab>
                                                    {/* Tab 2 Content (Quotations)*/}
                                                    <Tab eventKey="Paying customer" title="Paying customer">
                                                        <h3>Coming soon</h3>
                                                    </Tab>
                                                    {/* Tab 3 Content (Completed)*/}
                                                    <Tab eventKey="Agent" title="Agent">
                                                        <h3>Coming Soon</h3>
                                                    </Tab>
                                                </Tabs>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Right Sidebar for Search Filters */}
                                    {/* Card-5 */}
                                    <div className="col-md-4">
                                        <div className="card shadow">
                                            <div className="card-header py-3">
                                                <h5 className="m-0 font-weight-bold text-black">
                                                    <strong>Finance</strong>
                                                </h5>
                                            </div>
                                            <div className="card-body mb-5">
                                                <Tabs id="Customer" className="mb-3 mt-2 ml-1">
                                                    {/* Tab 1 Content (Customer)*/}
                                                    <Tab eventKey="Customer" title="Customer">
                                                        <table className="table">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Uw verblijf</td>
                                                                    <td />
                                                                    <td>€557.93</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Toeristenbelasting</td>
                                                                    <td> 6</td>
                                                                    <td> €9.60</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Reserveringskosten</td>
                                                                    <td>1</td>
                                                                    <td>€0.00</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Handdoekenpakket (p.p.)</td>
                                                                    <td>2</td>
                                                                    <td> €19.90</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Eindschoonmaak</td>
                                                                    <td>1</td>
                                                                    <td>€0.00</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Bedlinnen met opmaakservice</td>
                                                                    <td>1</td>
                                                                    <td>€0.00</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Keukendoekenpakket</td>
                                                                    <td>1</td>
                                                                    <td>€0.00</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Subtotaal</td>
                                                                    <td />
                                                                    <td>€587.43</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Borg</td>
                                                                    <td>1</td>
                                                                    <td>€65.00</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Total</th>
                                                                    <th />
                                                                    <th>€652.43</th>
                                                                </tr>
                                                                <tr>
                                                                    <td>Inclusief 9% BTW</td>
                                                                    <td />
                                                                    <td>€47.71</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <br />
                                                        <div className="payment-info">
                                                            <h5>Payment Information</h5>
                                                            <hr />
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'space-between',
                                                                }}
                                                            >
                                                                <div>
                                                                    <h6 style={{ color: 'green' }}>
                                                                        <strong>Reeds door u betaald</strong>
                                                                    </h6>
                                                                    <h5>
                                                                        <strong>€652.43</strong>
                                                                    </h5>
                                                                </div>
                                                                <div>
                                                                    <h6 style={{ color: 'red' }}>
                                                                        <strong>Nog door u te betalen</strong>
                                                                    </h6>
                                                                    <h5>
                                                                        <strong>€0.00</strong>
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                            <div className="text-end mt-3">
                                                                <button className="btn btn-secondary mr-2">
                                                                    Add voucher
                                                                </button>
                                                                <button className="btn btn-secondary mr-2">
                                                                    Store card
                                                                </button>
                                                                <button className="btn btn-primary">Pay bill</button>
                                                            </div>
                                                        </div>
                                                    </Tab>
                                                    {/* Tab 2 Content (Paying customer)*/}
                                                    <Tab eventKey="Paying customer" title="Paying customer">
                                                        <table className="table">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Uw verblijf</td>
                                                                    <td />
                                                                    <td>€557.93</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Toeristenbelasting</td>
                                                                    <td> 6</td>
                                                                    <td> €9.60</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Eindschoonmaak</td>
                                                                    <td>1</td>
                                                                    <td>€0.00</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Sub total</td>
                                                                    <td />
                                                                    <td>€566.6</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Total</th>
                                                                    <th />
                                                                    <th>€614.31</th>
                                                                </tr>
                                                                <tr>
                                                                    <td>Inclusief 9% BTW</td>
                                                                    <td />
                                                                    <td>€47.71</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <br />
                                                        <div className="payment-info">
                                                            <h5>Payment Information</h5>
                                                            <hr />
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'space-between',
                                                                }}
                                                            >
                                                                <div>
                                                                    <h6 style={{ color: 'green' }}>
                                                                        <strong>Reeds door u betaald</strong>
                                                                    </h6>
                                                                    <h5>
                                                                        <strong>€614.31</strong>
                                                                    </h5>
                                                                </div>
                                                                <div>
                                                                    <h6 style={{ color: 'red' }}>
                                                                        <strong>Nog door u te betalen</strong>
                                                                    </h6>
                                                                    <h5>
                                                                        <strong>€0.00</strong>
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                            <div className="text-end mt-3">
                                                                <button className="btn btn-secondary mr-2">
                                                                    Add voucher
                                                                </button>
                                                                <button className="btn btn-secondary mr-2">
                                                                    Store card
                                                                </button>
                                                                <button className="btn btn-primary">Pay bill</button>
                                                            </div>
                                                        </div>
                                                    </Tab>
                                                    {/* Tab 3 Content (Agent)*/}
                                                    <Tab eventKey="Agent" title="Agent">
                                                        <h3>Coming Soon</h3>
                                                    </Tab>
                                                </Tabs>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        {/* Tab 4 Content (More options)*/}

                        {/* More Options Tab with Hover */}
                        <Tab
                            eventKey="more options"
                            title={
                                <div className="dropdown">
                                    <NavLink className="dropdown-toggle-moreoptions" to="#" role="button">
                                        More details
                                        {''}
                                        <i className="fa-solid fa-caret-down" style={{ paddingLeft: '1rem' }} />
                                    </NavLink>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <Link
                                                className="dropdown-item"
                                                to="/client/reservation-manager/dashboard/accomodation-reservation/history"
                                            >
                                                History
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="dropdown-item"
                                                to="/client/reservation-manager/dashboard/accomodation-reservation/communication"
                                            >
                                                Communication
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="dropdown-item"
                                                to="/client/reservation-manager/dashboard/accomodation-reservation/finance"
                                            >
                                                Finance
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to="#">
                                                Documents
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to="#">
                                                External links
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to="#">
                                                Info texts
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to="#">
                                                Reservation add-ons
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to="#">
                                                Linked reservations
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            }
                        />
                    </Tabs>
                </header>
            </div>
        </div>
    );
};

export default HistoryPageHeader;
