import React from 'react';
import { Form } from 'react-bootstrap';
import HistoryPageHeader from './HistoryPageHeader';
import './Communication.css';

export const Communication = () => {
    return (
        <div className="container-fluid">
            <HistoryPageHeader />
            <div className="main-container bg-white rounded shadow-sm">
                <div className="card-title">
                    <h5 className="font-weight-bold text-black">
                        <strong>Communication (5)</strong>
                    </h5>
                </div>
                <hr />
                <div className="communication-container">
                    <div className="table-responsive">
                        <table className="communication-table">
                            <thead>
                                <tr>
                                    <th>Sent on</th>
                                    <th>Subject</th>
                                    <th>Sent by</th>
                                    <th>Sent to</th>
                                    <th>Task Notification Manager</th>
                                    <th>Is read</th>
                                    <th>Attachments</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        Th 14/11/2024, 14:03 <i className="fa-solid fa-envelope ml-5" />
                                    </td>
                                    <td>
                                        Important information about your Hoiliday 000256000070
                                        <br />
                                        Roompot DB - Arrival info Pin with pincode
                                    </td>
                                    <td>Carolien Kools</td>
                                    <td>kools.c@roompot.nl, elinebcc@roompot.nl</td>
                                    <td> - </td>
                                    <td>✔</td>
                                    <td>1 document</td>
                                </tr>
                                <tr>
                                    <td>
                                        Tu 17/09/2024, 20:17 <i className="fa-solid fa-envelope ml-5" />
                                    </td>
                                    <td>
                                        Confirmation of your booking 000256000070
                                        <br />
                                        Roompot DB - Final Confirmation
                                    </td>
                                    <td>Andries Akkerman</td>
                                    <td>akkerman.a@roompot.nl, elinebcc@roompot.nl</td>
                                    <td> - </td>
                                    <td>✔</td>
                                    <td>1 document</td>
                                </tr>
                                <tr>
                                    <td>
                                        Tu 13/08/2024, 20:55 <i className="fa-solid fa-envelope ml-5" />
                                    </td>
                                    <td>
                                        Payment Confirmation 000256000070
                                        <br />
                                        Roompot DB - Payment Confirmation
                                    </td>
                                    <td>Notification Manager</td>
                                    <td>wimfoekens@msn.com, elinebcc@roompot.nl</td>
                                    <td className="text-primary">Roompot: Betalingsbevestiging Roompot</td>
                                    <td>✔</td>
                                    <td>1 document</td>
                                </tr>
                                <tr>
                                    <td>
                                        Tu 13/08/2024, 15:26 <i className="fa-solid fa-envelope ml-5" />
                                    </td>
                                    <td>
                                        Dont forgot to pay for your holiday?
                                        <br />
                                        Roompot DB - Aanmaning 1
                                    </td>
                                    <td>Melanie Wegman</td>
                                    <td> - </td>
                                    <td> - </td>
                                    <td>✔</td>
                                    <td>1 document</td>
                                </tr>
                                <tr>
                                    <td>
                                        Tu 23/07/2024, 15:04 <i className="fa-solid fa-envelope ml-5" />
                                    </td>
                                    <td>
                                        Confirmation of your booking 000256000070
                                        <br />
                                        Roompot DB - Final confirmation
                                    </td>
                                    <td>Notification Manager</td>
                                    <td>wimfoekens@msn.com, elinebcc@roompot.nl</td>
                                    <td className="text-primary">Roompot: Confirmation regular</td>
                                    <td>✔</td>
                                    <td>1 document</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <hr />
                    <div className="text-end">
                        <button className="btn btn-primary mr-3">Download report</button>
                        <button className="btn btn-secondary">Send email</button>
                    </div>
                </div>
            </div>
            <div className="main-container bg-white rounded shadow-sm mb-5">
                <div className="card-title">
                    <h5 className="font-weight-bold text-black">
                        <strong>Statuses</strong>
                    </h5>
                </div>
                <hr />
                <div className="statuses-container">
                    <div className="row mb-4">
                        <div className="col-4">
                            <Form.Group>
                                <Form.Label>
                                    <strong>Arrival information status</strong>
                                </Form.Label>
                                <div className="dropdown-with-caret">
                                    <Form.Control as="select" defaultValue="">
                                        <option>Not sent</option>
                                    </Form.Control>
                                    <span className="caret" />
                                </div>
                            </Form.Group>
                        </div>
                        <br />
                        <div className="col-4">
                            <Form.Group>
                                <Form.Label>
                                    <strong>Reservation validation status</strong>
                                </Form.Label>
                                <div className="dropdown-with-caret">
                                    <Form.Control as="select" defaultValue="">
                                        <option>Sent</option>
                                    </Form.Control>
                                    <span className="caret" />
                                </div>
                            </Form.Group>
                        </div>
                        <br />
                        <div className="col-4">
                            <Form.Group>
                                <Form.Label>
                                    <strong>Repayment staus customer</strong>
                                </Form.Label>
                                <div className="dropdown-with-caret">
                                    <Form.Control as="select" defaultValue="">
                                        <option>Not specified</option>
                                    </Form.Control>
                                    <span className="caret" />
                                </div>
                            </Form.Group>
                        </div>
                    </div>
                    <hr />
                    <div className="text-end">
                        <button className="btn btn-secondary">Save statuses</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Communication;
