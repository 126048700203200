import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import HistoryPageHeader from './HistoryPageHeader';

export const Finance = () => {
    const [customerKey, setCustomerKey] = useState<string>('Customer');
    return (
        <div className="container-fluid">
            <HistoryPageHeader />
            <div className="main-container bg-white rounded shadow-sm">
                <div className="card-title">
                    <h5 className="font-weight-bold text-black">
                        <strong>Finance</strong>
                    </h5>
                </div>
                <hr />
                <div className="finance-container">
                    <Tabs
                        activeKey={customerKey}
                        onSelect={(k: string | null) => k && setCustomerKey(k)}
                        id="Customer"
                        className="mb-3 mt-2 ml-1"
                    >
                        {/* Tab 1 Content (Changed)*/}
                        <Tab eventKey="Customer" title="Customer">
                            <div className="card-title">
                                <h5 className="font-weight-bold text-black">
                                    <strong>Bill details</strong>
                                </h5>
                            </div>
                            <hr />
                            <table className="employee-table">
                                <thead>
                                    <tr>
                                        <th>Resource name</th>
                                        <th>Rate type</th>
                                        <th>Start date</th>
                                        <th>End date</th>
                                        <th>Calculation</th>
                                        <th>Total</th>
                                        <th>New price</th>
                                    </tr>
                                </thead>
                                <tbody style={{ color: '#000' }}>
                                    <tr>
                                        <td className="text-primary">Your Stay (no. 7521)</td>
                                        <td>Standard prices</td>
                                        <td>Fr 02/01/2026</td>
                                        <td>Mo 05/01/2026</td>
                                        <td>—</td>
                                        <td>€557.93</td>
                                        <td>—</td>
                                    </tr>
                                    <tr>
                                        <td className="text-primary">Tourist Tax</td>
                                        <td>Standard prices</td>
                                        <td>Fr 02/01/2026</td>
                                        <td>Mo 05/01/2026</td>
                                        <td>€1.60 × 6</td>
                                        <td>€9.60</td>
                                        <td>—</td>
                                    </tr>
                                    <tr>
                                        <td className="text-primary">Reservation fees</td>
                                        <td>Standard prices</td>
                                        <td>Fr 02/01/2026</td>
                                        <td>Mo 05/01/2026</td>
                                        <td>—</td>
                                        <td>€0.00</td>
                                        <td>—</td>
                                    </tr>
                                    <tr>
                                        <td className="text-primary">Towel package (p)</td>
                                        <td>Standard prices</td>
                                        <td>Fr 02/01/2026</td>
                                        <td>Mo 05/01/2026</td>
                                        <td>€9.95 × 2</td>
                                        <td>€19.90</td>
                                        <td>—</td>
                                    </tr>
                                    <tr>
                                        <td>Kitchen towel package</td>
                                        <td>Standard prices</td>
                                        <td>Fr 02/01/2026</td>
                                        <td>Mo 05/01/2026</td>
                                        <td>—</td>
                                        <td>€0.00</td>
                                        <td>—</td>
                                    </tr>
                                    <tr>
                                        <td>Subtotal</td>
                                        <td>—</td>
                                        <td>—</td>
                                        <td>—</td>
                                        <td>—</td>
                                        <td>€587.43</td>
                                        <td>—</td>
                                    </tr>
                                    <tr>
                                        <td className="text-primary">Borg</td>
                                        <td>—</td>
                                        <td>—</td>
                                        <td>—</td>
                                        <td>—</td>
                                        <td>€65.00</td>
                                        <td>—</td>
                                    </tr>
                                    <tr>
                                        <td>Total</td>
                                        <td>—</td>
                                        <td>—</td>
                                        <td>—</td>
                                        <td>—</td>
                                        <td>€652.43</td>
                                        <td>—</td>
                                    </tr>
                                    <tr>
                                        <td>Already paid by you</td>
                                        <td>—</td>
                                        <td>—</td>
                                        <td>—</td>
                                        <td>—</td>
                                        <td>€652.43</td>
                                        <td>—</td>
                                    </tr>
                                    <tr>
                                        <td>The abvove total amount includes 9% BTW</td>
                                        <td>—</td>
                                        <td>—</td>
                                        <td>—</td>
                                        <td>—</td>
                                        <td>€47.71</td>
                                        <td>—</td>
                                    </tr>
                                </tbody>
                            </table>

                            <hr />
                            <div className="d-flex justify-content-end">
                                <a className="text-black ml-3" href="#" style={{ textDecoration: 'none' }}>
                                    <strong>
                                        <input type="checkbox" className="mr-2" />
                                        Show VAT on the confirmation
                                    </strong>
                                </a>
                                <a className="text-black ml-3" href="#" style={{ textDecoration: 'none' }}>
                                    <strong>Paid by owner</strong>
                                </a>
                                <a className="text-black ml-3" href="#" style={{ textDecoration: 'none' }}>
                                    <strong>Pay bill</strong>
                                </a>
                                <a className="text-black ml-3" href="#" style={{ textDecoration: 'none' }}>
                                    <strong>Recalculate reservation</strong>
                                </a>
                                <a className="ml-3" href="#" style={{ textDecoration: 'none' }}>
                                    <strong>Overide rates</strong>
                                </a>
                                <a className="text-black ml-3" href="#" style={{ textDecoration: 'none' }}>
                                    <strong>Change payers</strong>
                                </a>
                            </div>
                            <br />
                            <div className="card-title">
                                <h5 className="font-weight-bold text-black">
                                    <strong>Internet Bill</strong>
                                </h5>
                            </div>
                            <hr />
                            <table className="employee-table">
                                <thead>
                                    <tr>
                                        <th>Resource name</th>
                                        <th>Rate type</th>
                                        <th>Start date</th>
                                        <th>End date</th>
                                        <th>Calculation</th>
                                        <th>Total</th>
                                        <th>New price</th>
                                    </tr>
                                </thead>
                                <tbody style={{ color: '#000' }}>
                                    <tr>
                                        <td>Interne lokale helffingen per nachit(Alin DC)</td>
                                        <td>Standard prices</td>
                                        <td>Fri 02/01/2026</td>
                                        <td>Mon 05/01/2026</td>
                                        <td />
                                        <td>
                                            <strong>€8.73</strong>
                                        </td>
                                        <td />
                                    </tr>
                                </tbody>
                            </table>
                            <hr />
                            <div className="d-flex justify-content-end">
                                <a className="text-black ml-3" href="#" style={{ textDecoration: 'none' }}>
                                    <strong>Override rates</strong>
                                </a>
                                <a className="text-black ml-3" href="#" style={{ textDecoration: 'none' }}>
                                    <strong>Change payers</strong>
                                </a>
                            </div>
                            <br />
                            <div className="card-title">
                                <h5 className="font-weight-bold text-black">
                                    <strong>Installments</strong>
                                </h5>
                            </div>
                            <hr />
                            <table className="employee-table">
                                <thead>
                                    <tr>
                                        <th>Term</th>
                                        <th>Issue date</th>
                                        <th>Due date</th>
                                        <th>Status</th>
                                        <th>Due amount</th>
                                        <th>Paid amount</th>
                                    </tr>
                                </thead>
                                <tbody style={{ color: '#000' }}>
                                    <tr>
                                        <td>50% Aanbetaling</td>
                                        <td>Tu 23/07/2024</td>
                                        <td>Tu 06/08/2024</td>
                                        <td>Closed</td>
                                        <td>
                                            <strong>€0.00</strong>
                                        </td>
                                        <td>
                                            <strong>€326.22</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Restbedrag</td>
                                        <td>Tu 23/07/2024</td>
                                        <td>Fri 07/11/2025</td>
                                        <td>Closed</td>
                                        <td>
                                            <strong>€0.00</strong>
                                        </td>
                                        <td>
                                            <strong>€326.21</strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <hr />
                            <div className="d-flex justify-content-end">
                                <a className="ml-3" href="#" style={{ textDecoration: 'none' }}>
                                    <strong>Change payment terms</strong>
                                </a>
                            </div>
                            <br />
                            <div className="card-title">
                                <h5 className="font-weight-bold text-black">
                                    <strong>Payment method</strong>
                                </h5>
                            </div>
                            <hr />
                            <table className="employee-table">
                                <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Payer type</th>
                                        <th>Date</th>
                                        <th>Remark</th>
                                        <th>Amount</th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody style={{ color: '#000' }}>
                                    <tr>
                                        <td>Payment</td>
                                        <td>Customer</td>
                                        <td>Tu 13/08/2024</td>
                                        <td />
                                        <td>
                                            <strong>€652.43</strong>
                                        </td>
                                        <td />
                                    </tr>
                                </tbody>
                            </table>
                            <hr />
                            <div className="d-flex justify-content-end">
                                <a className="text-black ml-3" href="#" style={{ textDecoration: 'none' }}>
                                    <strong>Refund</strong>
                                </a>
                                <a className="ml-3" href="#" style={{ textDecoration: 'none' }}>
                                    <strong>Tranfer payment</strong>
                                </a>
                            </div>
                            <br />
                            <div className="card-title">
                                <h5 className="font-weight-bold text-black">
                                    <strong>Redeemed vouchers</strong>
                                </h5>
                            </div>
                            <hr />
                            <table className="employee-table">
                                <thead>
                                    <tr>
                                        <th>Code</th>
                                        <th>Amount used in this reservation</th>
                                        <th>Original amount</th>
                                        <th>Remaining amount</th>
                                    </tr>
                                </thead>
                            </table>
                            <div className="center-content mt-5" style={{ marginLeft: '50%', color: '#000' }}>
                                <i className="fa-solid fa-list fa-2x" />
                            </div>
                            <h6 className="text-black mt-3" style={{ marginLeft: '30%' }}>
                                Theres nothing here at the moment. Start by adding new items to see them appear.
                            </h6>
                            <hr />
                            <div className="d-flex justify-content-end">
                                <a className="text-black ml-3" href="#" style={{ textDecoration: 'none' }}>
                                    <strong>Withdraw vouchers</strong>
                                </a>
                            </div>
                            <br />
                            <div className="card-title">
                                <h5 className="font-weight-bold text-black">
                                    <strong>Reminders</strong>
                                </h5>
                            </div>
                            <hr />
                            <table className="employee-table">
                                <thead>
                                    <tr>
                                        <th>Status</th>
                                        <th>Created by</th>
                                        <th>Issue by</th>
                                        <th>Printed date</th>
                                        <th>Due date</th>
                                        <th>Due amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style={{ color: '#000' }}>
                                        <td>herinnering betaaldatum</td>
                                        <td>Melenie Wegman</td>
                                        <td>Tu 13/08/2024, 12:43</td>
                                        <td>Tu 13/08/2024</td>
                                        <td>Wed 14/08/2024</td>
                                        <td>
                                            <strong>€326.22</strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <hr />
                            <div className="d-flex justify-content-end">
                                <a className="ml-3" href="#" style={{ textDecoration: 'none' }}>
                                    <strong>Puts reminder on hold</strong>
                                </a>
                            </div>
                        </Tab>
                        {/* Tab 2 Content (Quotations)*/}
                        <Tab eventKey="Paying customer" title="Paying customer">
                            <h3>Coming soon</h3>
                        </Tab>
                        {/* Tab 3 Content (Completed)*/}
                        <Tab eventKey="Agent" title="Agent">
                            <h3>Coming Soon</h3>
                        </Tab>
                        {/* Tab 4 Content (Completed)*/}
                        <Tab eventKey="Costs" title="Costs">
                            <h3>Coming Soon</h3>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    );
};

export default Finance;
